import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import SEO from "../components/seo"
import './index.scss'

import imgDeepProfile from '../images/deep-profile.svg'
import imgProfiler from '../images/profiler.svg'
import imgIndicator from '../images/indicator.svg'

import MapContainer from '../components/MapContainer'
import ContactUsForm from '../components/ContactUsForm'

import { scrollToNode } from '../helpers/scrollToNode'

const IndexPage = () => {

	const data = useStaticQuery(graphql`
	query {
		logo_nikkeibp: file(relativePath: { eq: "nikkeibp-white.png" }) {
			childImageSharp {
				fluid(maxWidth: 440, maxHeight: 76) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
		technology_overview: file(relativePath: { eq: "technology-overview.png" }) {
			childImageSharp {
				fluid(maxWidth: 3054, maxHeight: 2698) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
	  }
	`)

	const scrollToDiv = () => {
		let node = document.getElementById("about")
		scrollToNode(node)
	}

	return (

		<>
			<SEO title="Home" />
			<div className="index-page">

				{/* Content: Landing Intro */}

				<section className="bg--primary top-section">
					<div className="top-section-bg">
						<div className="bg-lines"></div>
						<div className="bg-lines"></div>
						<div className="bg-lines"></div>
					</div>
					<div className="container content">
						<div className="row">
							<div className="col-sm-6 col-md-6">
								<h3 className="headline animation-element" alt="Make Sense of Your Data, Make Better Decisions">
									<div className="slideInLeft" style={{ transitionDelay: '0.5s' }}>
										Make Sense of Your Data,
									</div>
									<div className="slideInLeft" style={{ transitionDelay: '0.6s' }}>
										Make Better Decisions
									</div>
								</h3>
								<p style={{fontSize: '110%'}}>We are an AI company that builds computational models for everything that makes us human, so that our clients can understand people better at scale, in diverse application domains such as HR, Retail, Finance, and more.</p>
								<a onClick={scrollToDiv} className="btn-highlight inverted mt-3 mb-5">
									<span className="highlight__background">
										<span className="highlight__background-inner"></span>
									</span>
									<span className="highlight__link-text">Learn more</span>
								</a>
								<div className="featured">
									<div className="mb-3">Featured on</div>
									<a href="https://tech.nikkeibp.co.jp/atcl/nxt/news/18/03845/" target="_blank" rel="noopener noreferrer">
										<Image
											className="featured-image"
											fluid={data.logo_nikkeibp.childImageSharp.fluid}
											alt="Nikkei BP" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>



				{/* Content: About */}

				<section className="bg--secondary" id="about">
					<div className="container content">
						<h1 className="section-title">We help you</h1>
						<div className="row flex-nowrap mobile-scroll">
							<div className="help-card">
								<div className="round-container round-container__padding basic-fly-in-from-right animation-element"
									style={{ transitionDelay: '0.4s', transitionDuration: '0.5s' }}>
									<div className="feat-container">
										<div className="col-sm-12 mb-3">
											<img src={imgProfiler} style={{ height: '80px', maxWidth: '100%' }} alt="" />
										</div>
										<div className="col-sm-12">
											<h2 className="text-primary" style={{ transitionDelay: '0.9s' }}>Create Holistic Profiles</h2>
											<p>We do not just follow standard black-box approaches. We start by reviewing published scientific literature that are rigorous and peer-reviewed, in the relevant application domain. Based on this evidence, we build explainable belief models that maps sparse input data to thousands of related human constructs.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="help-card">
								<div className="round-container round-container__padding basic-fly-in-from-right animation-element"
									style={{ transitionDelay: '0.45s', transitionDuration: '0.5s' }}>
									<div className="feat-container">
										<div className="col-sm-12 mb-3">
											<img src={imgIndicator} style={{ height: '80px', maxWidth: '100%' }} alt="" />
										</div>
										<div className="col-sm-12">
											<h2 className="text-primary" style={{ transitionDelay: '1s' }}>Incorporate Past Data</h2>
											<p>We know that a huge volume of relevant past data reside in many various parts of an organization. This can be in the form of paper-work, databases, or even in intangible forms, like past experience gathered from years of work. We can incorporate these past data into our computational models.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="help-card">
								<div className="round-container round-container__padding basic-fly-in-from-right animation-element"
									style={{ transitionDelay: '0.5s', transitionDuration: '0.5s' }}>
									<div className="feat-container">
										<div className="col-sm-12 mb-3">
											<img src={imgDeepProfile}
												style={{ height: '80px', maxWidth: '100%' }} alt="" />
										</div>
										<div className="col-sm-12">
											<h2 className="text-primary" style={{ transitionDelay: '1.1s' }}>Understand people well</h2>
											<p>Our key strength is in helping your organization understand people deeply, at scale, even with sparse data, so that you can make better decisions for your business. From HR needs, such as job placement and team-fit, to retail consumer segmentation, to finance KYC, our methods translate equally well.</p>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</section>


				{/* Content: Technology Overview */}

				<section className="bg--tertiary" id="solution">
					<div className="container content">
						<h1 className="section-title-secondary">Why Mercurics?</h1>
						<div className="row">
							<div className="engine-image">
								<Image
									style={{ maxWidth: '100%' }}
									fluid={data.technology_overview.childImageSharp.fluid}
									alt="Nikkei BP" />
							</div>
							<div className="engine-description">
								<div style={{ transitionDelay: '0.4s', transitionDuration: '0.5s' }}>
									<div className="feat-container">
										<div className="col-sm-12">
											<h2 className="text-primary" style={{ transitionDelay: '0.9s' }}>Mercurics Cognitive AI Engine</h2>
											<p>By fusing <b>Artificial Intelligence</b> with <b>Psychology</b>, our engine helps you to see patterns in complex situations using personality data, so you can make better decisions.</p>
											<p>One example is hiring in HR. Should you hire a candidate? What kind of candidate are you looking for? Our engine turns data, even sparse data, into clear, understandable metrics that you can use. This translates to transparent decision-making with greater certainty backed by research and data.</p>
											<p>By utilizing Mercurics’ Cognitive AI Engine we can substantially reduce the amount of questions required as the gaps are filled with the AI’s inference. Getting more valuable output for less input.</p>
											<div className="btn-container">
												<Link className="btn btn-outline-primary" to='/personal-discovery'>Personal Discovery for Individuals</Link>
												<Link className="btn btn-outline-primary" to='/people-analytics'>People Analytics for Companies</Link>
												<Link className="btn btn-outline-primary" to='/customized-solutions'>Customized Solutions</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="bg--quaternary contact-us" id="contact-us-mobile">
					<div className="container content">
						<h1 className="section-title-secondary-contact">We'd love to hear from you</h1>
						<div className="row">
							<div className="contact-form-container">
								<ContactUsForm showText={true} />
							</div>
							<div className="find-us-container">
								<div className="row">
									<div className="col-5">
										<h1>Find us at</h1>
									</div>
									<div className="col-7 address">
										<svg className="icon icon-location"><use xlinkHref="#icon-location"></use></svg><span className="bold">Singapore</span><br/>
										the Hive Lavender<br/>Level 6, Vanguard Building<br/>1 Kallang Junction, Singapore 339263
									</div>
								</div>
								<div className="row map-container">
									<MapContainer />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="bg--quaternary contact-us" id="contact-us-web">
					<div className="container content">
						<h1 className="section-title-secondary-contact">We'd love to hear from you</h1>
						<div className="row">
							<div className="contact-form-container">
								<ContactUsForm showText={true} />
							</div>
							<div className="find-us-container">
								<div className="row">
									<div className="col-5">
										<h1>Find us at</h1>
									</div>
									<div className="col-7 address">
										<svg className="icon icon-location"><use xlinkHref="#icon-location"></use></svg><span className="bold">Singapore</span><br/>
										the Hive Lavender<br/>Level 6, Vanguard Building<br/>1 Kallang Junction, Singapore 339263
									</div>
								</div>
								<div className="row">
									<MapContainer />
								</div>
							</div>
						</div>
					</div>
				</section>

				<svg className="hidden">
					<symbol id="icon-selfi-logo" viewBox="0 0 88 32">
						<path fill="#fff" d="M16.97 1.455c0 0.803-0.434 1.455-0.97 1.455s-0.97-0.651-0.97-1.455c0-0.803 0.434-1.455 0.97-1.455s0.97 0.651 0.97 1.455z"></path>
						<path fill="#555af7" d="M16 2.909c-0.543 0-0.97-0.64-0.97-1.455s0.427-1.455 0.97-1.455 0.97 0.64 0.97 1.455-0.427 1.455-0.97 1.455zM16 0.485c-0.228 0-0.485 0.417-0.485 0.97s0.257 0.97 0.485 0.97 0.485-0.417 0.485-0.97-0.257-0.97-0.485-0.97z"></path>
						<path fill="#fff" d="M16.97 30.788c0 0.669-0.434 1.212-0.97 1.212s-0.97-0.543-0.97-1.212c0-0.669 0.434-1.212 0.97-1.212s0.97 0.543 0.97 1.212z"></path>
						<path fill="#555af7" d="M16 32c-0.561-0.127-0.974-0.621-0.974-1.212s0.413-1.085 0.966-1.211l0.008-0.002c0.561 0.127 0.974 0.621 0.974 1.212s-0.413 1.085-0.966 1.211l-0.008 0.002zM16 30.061c-0.262 0-0.485 0.335-0.485 0.727s0.223 0.727 0.485 0.727 0.485-0.335 0.485-0.727-0.223-0.727-0.485-0.727z"></path>
						<path fill="#fff" d="M32 16c0 0.536-0.543 0.97-1.212 0.97s-1.212-0.434-1.212-0.97c0-0.536 0.543-0.97 1.212-0.97s1.212 0.434 1.212 0.97z"></path>
						<path fill="#555af7" d="M30.788 16.97c-0.032 0.003-0.069 0.005-0.106 0.005-0.566 0-1.034-0.422-1.106-0.969l-0.001-0.006c0.127-0.561 0.621-0.974 1.212-0.974s1.085 0.413 1.211 0.966l0.002 0.008c-0.072 0.553-0.54 0.975-1.106 0.975-0.037 0-0.074-0.002-0.11-0.005l0.005 0zM30.788 15.515c-0.393 0-0.727 0.223-0.727 0.485s0.335 0.485 0.727 0.485 0.727-0.223 0.727-0.485-0.335-0.485-0.727-0.485z"></path>
						<path fill="#fff" d="M2.909 16c0 0.536-0.651 0.97-1.455 0.97s-1.455-0.434-1.455-0.97c0-0.536 0.651-0.97 1.455-0.97s1.455 0.434 1.455 0.97z"></path>
						<path fill="#555af7" d="M1.455 16.97c-0.815 0-1.455-0.427-1.455-0.97s0.64-0.97 1.455-0.97 1.455 0.427 1.455 0.97-0.64 0.97-1.455 0.97zM1.455 15.515c-0.553 0-0.97 0.257-0.97 0.485s0.417 0.485 0.97 0.485 0.97-0.257 0.97-0.485-0.417-0.485-0.97-0.485z"></path>
						<path fill="#fff" d="M27.762 5.731c0 0.718-0.582 1.299-1.299 1.299s-1.299-0.582-1.299-1.299c0-0.718 0.582-1.299 1.299-1.299s1.299 0.582 1.299 1.299z"></path>
						<path fill="#555af7" d="M26.463 7.030c-0.716-0.003-1.295-0.583-1.295-1.299 0-0.718 0.582-1.299 1.299-1.299 0.358 0 0.681 0.144 0.916 0.378l-0-0c0.237 0.235 0.383 0.561 0.383 0.921 0 0.718-0.582 1.299-1.299 1.299-0.002 0-0.003 0-0.005 0h0zM26.463 4.916c-0.001 0-0.003 0-0.004 0-0.224 0-0.426 0.091-0.573 0.238v0c-0.156 0.149-0.253 0.358-0.253 0.59 0 0.45 0.365 0.815 0.815 0.815 0.233 0 0.444-0.098 0.592-0.255l0-0c0.148-0.147 0.24-0.352 0.24-0.577 0-0.45-0.365-0.815-0.815-0.815-0.001 0-0.001 0-0.002 0h0z"></path>
						<path fill="#fff" d="M6.919 26.575c0 0.718-0.582 1.299-1.299 1.299s-1.299-0.582-1.299-1.299c0-0.718 0.582-1.299 1.299-1.299s1.299 0.582 1.299 1.299z"></path>
						<path fill="#555af7" d="M5.619 27.879c-0.717-0.004-1.297-0.586-1.297-1.304 0-0.357 0.144-0.681 0.376-0.916l-0 0c0.238-0.229 0.562-0.371 0.919-0.371s0.681 0.141 0.919 0.371l-0-0c0.233 0.235 0.376 0.559 0.376 0.916 0 0.716-0.577 1.298-1.292 1.304h-0.001zM5.619 25.76c-0.449 0.001-0.813 0.365-0.813 0.815s0.365 0.815 0.815 0.815 0.815-0.365 0.815-0.815c0-0.225-0.092-0.429-0.24-0.577l-0-0c-0.146-0.147-0.348-0.238-0.571-0.238-0.002 0-0.004 0-0.006 0h0z"></path>
						<path fill="#fff" d="M27.762 26.575c0 0.718-0.582 1.299-1.299 1.299s-1.299-0.582-1.299-1.299c0-0.718 0.582-1.299 1.299-1.299s1.299 0.582 1.299 1.299z"></path>
						<path fill="#555af7" d="M26.463 27.879c-0.001 0-0.001 0-0.002 0-0.72 0-1.304-0.584-1.304-1.304s0.584-1.304 1.304-1.304c0.72 0 1.304 0.584 1.304 1.304 0 0.36-0.146 0.685-0.381 0.921l0-0c-0.235 0.237-0.561 0.383-0.921 0.383v0zM26.463 25.76c-0.001 0-0.003 0-0.004 0-0.224 0-0.426 0.091-0.573 0.238v0c-0.146 0.149-0.236 0.352-0.236 0.577s0.090 0.428 0.236 0.577l-0-0c0.147 0.147 0.351 0.238 0.575 0.238 0.45 0 0.815-0.365 0.815-0.815s-0.364-0.813-0.812-0.815h-0z"></path>
						<path fill="#fff" d="M6.919 5.731c0 0.718-0.582 1.299-1.299 1.299s-1.299-0.582-1.299-1.299c0-0.718 0.582-1.299 1.299-1.299s1.299 0.582 1.299 1.299z"></path>
						<path fill="#555af7" d="M5.619 7.030c-0.001 0-0.003 0-0.005 0-0.718 0-1.299-0.582-1.299-1.299 0 0 0 0 0 0v0c0-0.001 0-0.003 0-0.004 0-0.359 0.146-0.683 0.383-0.917l0-0c0.239-0.227 0.562-0.367 0.919-0.367s0.68 0.14 0.919 0.368l-0.001-0.001c0.237 0.235 0.383 0.561 0.383 0.921 0 0.718-0.582 1.299-1.299 1.299v0zM5.619 4.916c-0.002 0-0.004-0-0.006-0-0.223 0-0.425 0.091-0.571 0.238l-0 0c-0.137 0.146-0.222 0.342-0.222 0.559 0 0.45 0.365 0.815 0.815 0.815 0.218 0 0.416-0.085 0.562-0.225l-0 0c0.146-0.149 0.236-0.352 0.236-0.577s-0.090-0.428-0.236-0.577l0 0c-0.146-0.144-0.347-0.233-0.568-0.233-0.003 0-0.006 0-0.009 0h0z"></path>
						<path fill="#555af7" d="M37.251 20.533c1.346 0.715 2.943 1.135 4.638 1.135 0.001 0 0.002 0 0.002 0h-0c0.092 0.008 0.199 0.013 0.307 0.013 0.639 0 1.24-0.165 1.762-0.454l-0.019 0.009c0.365-0.292 0.596-0.737 0.596-1.237 0-0.043-0.002-0.085-0.005-0.127l0 0.006c0-0.015 0.001-0.033 0.001-0.050 0-0.374-0.111-0.722-0.301-1.014l0.004 0.007c-0.272-0.358-0.605-0.653-0.988-0.874l-0.016-0.009c-0.485-0.291-1.202-0.679-2.196-1.154-1.244-0.586-2.308-1.337-3.22-2.241l0.001 0.001c-0.769-0.8-1.243-1.89-1.243-3.090 0-0.049 0.001-0.098 0.002-0.146l-0 0.007c-0.003-0.056-0.004-0.122-0.004-0.188 0-0.954 0.315-1.834 0.846-2.543l-0.008 0.011c0.572-0.712 1.335-1.248 2.212-1.532l0.033-0.009c0.918-0.308 1.974-0.486 3.072-0.486 0.047 0 0.093 0 0.14 0.001l-0.007-0c0.034-0 0.075-0.001 0.115-0.001 0.953 0 1.877 0.122 2.758 0.352l-0.076-0.017c0.702 0.157 1.322 0.403 1.889 0.73l-0.032-0.017-0.616 2.909c-0.508-0.322-1.094-0.603-1.712-0.812l-0.058-0.017c-0.618-0.203-1.329-0.321-2.067-0.321-0.037 0-0.074 0-0.11 0.001l0.006-0c-0.080-0.007-0.174-0.010-0.268-0.010-0.663 0-1.284 0.184-1.813 0.504l0.016-0.009c-0.396 0.287-0.651 0.748-0.651 1.268 0 0.023 0.001 0.046 0.001 0.068l-0-0.003c-0 0.013-0.001 0.029-0.001 0.045 0 0.378 0.118 0.729 0.32 1.018l-0.004-0.006c0.259 0.336 0.573 0.616 0.93 0.83l0.015 0.008c0.407 0.267 1.033 0.616 1.867 1.047 1.068 0.539 1.958 1.064 2.812 1.639l-0.092-0.058c0.651 0.446 1.192 1.004 1.606 1.65l0.014 0.023c0.385 0.628 0.613 1.388 0.613 2.201 0 0.043-0.001 0.085-0.002 0.127l0-0.006c0.001 0.039 0.002 0.084 0.002 0.13 0 0.943-0.289 1.818-0.783 2.543l0.010-0.016c-0.542 0.751-1.28 1.33-2.14 1.666l-0.032 0.011c-0.912 0.363-1.969 0.574-3.075 0.574-0.061 0-0.122-0.001-0.183-0.002l0.009 0c-0.785-0.002-1.554-0.063-2.305-0.181l0.085 0.011c-0.773-0.116-1.441-0.263-2.093-0.45l0.114 0.028c-0.46-0.12-0.864-0.286-1.237-0.498l0.025 0.013z"></path>
						<path fill="#555af7" d="M62.017 18.565h-7.981c-0 0.006-0 0.012-0 0.019 0 0.969 0.401 1.843 1.046 2.468l0.001 0.001c0.638 0.514 1.459 0.825 2.352 0.825 0.070 0 0.139-0.002 0.208-0.006l-0.010 0c0.67-0.015 1.313-0.090 1.935-0.22l-0.069 0.012c0.599-0.091 1.139-0.26 1.64-0.5l-0.035 0.015 0.601 2.095c-0.587 0.438-1.28 0.772-2.030 0.961l-0.040 0.009c-0.861 0.249-1.85 0.393-2.873 0.393-0.013 0-0.025-0-0.038-0h0.002c-0.061 0.002-0.132 0.003-0.204 0.003-1.194 0-2.318-0.301-3.3-0.831l0.037 0.018c-0.949-0.538-1.696-1.337-2.154-2.301l-0.013-0.031c-0.464-1.010-0.735-2.191-0.735-3.435 0-0.081 0.001-0.162 0.003-0.242l-0 0.012c-0.001-0.040-0.001-0.087-0.001-0.133 0-1.21 0.285-2.354 0.792-3.367l-0.020 0.044c0.494-1.013 1.248-1.833 2.176-2.396l0.025-0.014c0.957-0.545 2.103-0.866 3.324-0.866 0.065 0 0.13 0.001 0.195 0.003l-0.010-0c0.029-0 0.064-0.001 0.098-0.001 0.922 0 1.8 0.187 2.598 0.526l-0.044-0.016c0.799 0.347 1.449 0.913 1.89 1.621l0.010 0.018c0.465 0.789 0.739 1.738 0.739 2.751 0 0.056-0.001 0.111-0.002 0.166l0-0.008c0.003 0.115 0.004 0.251 0.004 0.387 0 0.713-0.044 1.416-0.129 2.106l0.008-0.083zM58.725 16.058c0.004-0.054 0.006-0.118 0.006-0.182 0-0.584-0.184-1.124-0.497-1.567l0.006 0.009c-0.354-0.37-0.851-0.599-1.402-0.599-0.061 0-0.122 0.003-0.181 0.008l0.008-0.001c-0.035-0.002-0.075-0.003-0.116-0.003-0.627 0-1.197 0.243-1.621 0.639l0.001-0.001c-0.487 0.584-0.795 1.331-0.834 2.15l-0 0.008h4.616c0.010-0.107 0.015-0.257 0.015-0.461z"></path>
						<path fill="#555af7" d="M69.115 4.931v19.675h-3.69v-19.675z"></path>
						<path fill="#555af7" d="M72.242 13.668v-2.158l1.833-0.402v-1.241c-0.004-0.073-0.006-0.16-0.006-0.246 0-0.984 0.276-1.904 0.756-2.685l-0.013 0.022c0.453-0.7 1.093-1.245 1.85-1.57l0.027-0.010c0.716-0.307 1.549-0.485 2.424-0.485v0c0.501 0.002 0.993 0.036 1.475 0.098l-0.059-0.006c0.365 0.037 0.695 0.097 1.016 0.18l-0.046-0.010v2.613c-0.473-0.173-1.020-0.274-1.59-0.274-0.091 0-0.18 0.003-0.27 0.008l0.012-0.001c-0.022-0.001-0.049-0.002-0.075-0.002-0.519 0-0.985 0.227-1.305 0.587l-0.002 0.002c-0.324 0.422-0.52 0.958-0.52 1.54 0 0.060 0.002 0.12 0.006 0.18l-0-0.008v1.285h3.151v2.56h-3.151v10.962h-3.695v-10.938z"></path>
						<path fill="#555af7" d="M83.345 6.017c0.224-0.337 0.519-0.611 0.865-0.803l0.013-0.006c0.329-0.189 0.724-0.3 1.144-0.301h0c0.031-0.002 0.067-0.003 0.103-0.003 0.559 0 1.068 0.215 1.449 0.566l-0.001-0.001c0.36 0.352 0.583 0.843 0.583 1.386 0 0.024-0 0.048-0.001 0.072l0-0.003c-0.001 0.411-0.122 0.793-0.33 1.114l0.005-0.008c-0.221 0.34-0.515 0.615-0.86 0.808l-0.012 0.006c-0.33 0.189-0.726 0.301-1.148 0.301-0.002 0-0.004 0-0.006 0h0c-0.010 0-0.021 0-0.032 0-0.386 0-0.749-0.097-1.066-0.268l0.012 0.006c-0.63-0.329-1.052-0.978-1.052-1.725 0-0.012 0-0.025 0-0.037l-0 0.002c0-0.002 0-0.005 0-0.008 0-0.409 0.125-0.79 0.339-1.105l-0.004 0.007zM87.156 11.108v13.498h-3.695v-13.498z"></path>
						<path fill="#b3b3b3" opacity="0.29" d="M16.141 5.891c-4.992 0.005-9.143 3.602-10.008 8.345l-0.009 0.062c0 2.104 0.868 3.845 2.225 4.116l0.111-0.053c0.091 0.037 0.195 0.058 0.305 0.058 0.031 0 0.061-0.002 0.091-0.005l-0.004 0c1.278-0.005 2.395-0.691 3.007-1.715l0.009-0.016c0.43-0.767 0.879-1.703 1.274-2.668l0.064-0.178c0.692-1.6 1.496-2.978 2.439-4.245l-0.039 0.055c1.537-1.939 3.505-2.545 5.852-2.245-1.51-0.949-3.345-1.513-5.313-1.513-0.002 0-0.004 0-0.007 0h0z"></path>
						<path fill="#b3b3b3" opacity="0.29" d="M15.859 26.109c4.992-0.005 9.143-3.602 10.008-8.345l0.009-0.062c0-2.104-0.868-3.845-2.225-4.116l-0.111 0.053c-0.090-0.037-0.195-0.058-0.305-0.058-0.031 0-0.061 0.002-0.091 0.005l0.004-0c-1.278 0.005-2.395 0.691-3.007 1.715l-0.009 0.016c-0.43 0.767-0.879 1.703-1.274 2.668l-0.064 0.178c-0.692 1.6-1.496 2.978-2.439 4.245l0.039-0.055c-1.537 1.939-3.505 2.545-5.852 2.245 1.51 0.949 3.345 1.513 5.313 1.513 0.002 0 0.004 0 0.007 0h-0z"></path>
						<path fill="#555af7" d="M16.781 20.364c-0.97-0.485-1.896-0.97-2.846-1.396-1.601-0.717-2.977-1.527-4.251-2.468l0.062 0.044c-1.939-1.537-2.545-3.515-2.245-5.867-0.977 1.532-1.557 3.4-1.557 5.404 0 5.050 3.686 9.238 8.515 10.021l0.058 0.008c2.051-0.029 3.675-0.897 3.942-2.221v-0.131c0.035-0.096 0.055-0.207 0.055-0.323 0-0.023-0.001-0.045-0.002-0.068l0 0.003c-0.007-1.275-0.693-2.388-1.715-2.997l-0.016-0.009z"></path>
						<path fill="#555af7" d="M15.219 11.636c0.97 0.485 1.896 0.97 2.846 1.396 1.601 0.717 2.977 1.527 4.251 2.468l-0.062-0.044c1.939 1.537 2.545 3.515 2.245 5.867 0.977-1.532 1.557-3.4 1.557-5.404 0-5.050-3.686-9.238-8.515-10.021l-0.058-0.008c-2.051 0.029-3.675 0.897-3.942 2.221v0.131c-0.035 0.096-0.056 0.207-0.056 0.323 0 0.023 0.001 0.045 0.002 0.068l-0-0.003c0.007 1.275 0.693 2.388 1.715 2.997l0.016 0.009z"></path>
					</symbol>
					<symbol id="icon-selfi-corp" viewBox="0 0 88 32">
						<path fill="#20245b" d="M16 2.909c-0.543 0-0.97-0.64-0.97-1.455s0.427-1.455 0.97-1.455 0.97 0.64 0.97 1.455-0.427 1.455-0.97 1.455zM16 0.485c-0.228 0-0.485 0.417-0.485 0.97s0.257 0.97 0.485 0.97 0.485-0.417 0.485-0.97-0.257-0.97-0.485-0.97z"></path>
						<path fill="#20245b" d="M16 32c-0.561-0.127-0.974-0.621-0.974-1.212s0.413-1.085 0.966-1.211l0.008-0.002c0.561 0.127 0.974 0.621 0.974 1.212s-0.413 1.085-0.966 1.211l-0.008 0.002zM16 30.061c-0.262 0-0.485 0.335-0.485 0.727s0.223 0.727 0.485 0.727 0.485-0.335 0.485-0.727-0.223-0.727-0.485-0.727z"></path>
						<path fill="#20245b" d="M30.788 16.97c-0.032 0.003-0.069 0.005-0.106 0.005-0.566 0-1.034-0.422-1.106-0.969l-0.001-0.006c0.127-0.561 0.621-0.974 1.212-0.974s1.085 0.413 1.211 0.966l0.002 0.008c-0.072 0.553-0.54 0.975-1.106 0.975-0.037 0-0.074-0.002-0.11-0.005l0.005 0zM30.788 15.515c-0.393 0-0.727 0.223-0.727 0.485s0.335 0.485 0.727 0.485 0.727-0.223 0.727-0.485-0.335-0.485-0.727-0.485z"></path>
						<path fill="#20245b" d="M1.455 16.97c-0.815 0-1.455-0.427-1.455-0.97s0.64-0.97 1.455-0.97 1.455 0.427 1.455 0.97-0.64 0.97-1.455 0.97zM1.455 15.515c-0.553 0-0.97 0.257-0.97 0.485s0.417 0.485 0.97 0.485 0.97-0.257 0.97-0.485-0.417-0.485-0.97-0.485z"></path>
						<path fill="#20245b" d="M26.463 7.030c-0.716-0.003-1.295-0.583-1.295-1.299 0-0.718 0.582-1.299 1.299-1.299s1.299 0.582 1.299 1.299c0 0.358-0.144 0.681-0.378 0.916l0-0c-0.235 0.237-0.561 0.383-0.921 0.383-0.002 0-0.003 0-0.005 0h0zM26.463 4.916c-0.002 0-0.004-0-0.006-0-0.223 0-0.425 0.091-0.571 0.238l-0 0c-0.16 0.149-0.261 0.361-0.261 0.597 0 0.45 0.365 0.815 0.815 0.815 0.222 0 0.424-0.089 0.571-0.234l0.029-0.029c0.148-0.147 0.24-0.352 0.24-0.577 0-0.45-0.365-0.815-0.815-0.815-0.001 0-0.001 0-0.002 0h0z"></path>
						<path fill="#20245b" d="M5.619 27.879c-0.719-0.006-1.299-0.589-1.299-1.309 0 0 0-0 0-0v0c0.005-0.713 0.585-1.29 1.299-1.29 0.358 0 0.681 0.144 0.916 0.378l-0-0c0.233 0.235 0.376 0.559 0.376 0.916 0 0.716-0.577 1.298-1.292 1.304h-0.001zM5.619 25.76c-0.45 0-0.815 0.365-0.815 0.815s0.365 0.815 0.815 0.815c0.45 0 0.815-0.365 0.815-0.815v0c0-0.002 0-0.005 0-0.008 0-0.223-0.091-0.424-0.238-0.569l-0-0c-0.145-0.147-0.346-0.238-0.569-0.238-0.003 0-0.006 0-0.008 0h0z"></path>
						<path fill="#20245b" d="M26.463 27.879c-0.72 0-1.304-0.584-1.304-1.304s0.584-1.304 1.304-1.304c0.72 0 1.304 0.584 1.304 1.304v0c-0.002 0.36-0.148 0.685-0.383 0.921l0-0c-0.234 0.237-0.558 0.383-0.917 0.383-0.001 0-0.003 0-0.004 0h0zM26.463 25.76c-0.002 0-0.004-0-0.006-0-0.223 0-0.425 0.091-0.571 0.238l-0 0c-0.148 0.148-0.24 0.352-0.24 0.577 0 0.45 0.365 0.815 0.815 0.815s0.815-0.365 0.815-0.815c0-0.449-0.364-0.813-0.812-0.815h-0z"></path>
						<path fill="#20245b" d="M5.619 7.030c-0.001 0-0.003 0-0.005 0-0.716 0-1.297-0.579-1.299-1.294v-0c0-0.001 0-0.003 0-0.004 0-0.359 0.146-0.683 0.383-0.917l0-0c0.239-0.227 0.562-0.367 0.919-0.367s0.68 0.14 0.919 0.368l-0.001-0.001c0.237 0.235 0.383 0.561 0.383 0.921 0 0.718-0.582 1.299-1.299 1.299v0zM5.619 4.916c-0.002-0-0.005-0-0.008-0-0.223 0-0.424 0.091-0.569 0.238l-0 0c-0.137 0.146-0.222 0.342-0.222 0.559 0 0.45 0.365 0.815 0.815 0.815 0.218 0 0.416-0.085 0.562-0.225l-0 0c0.146-0.149 0.236-0.352 0.236-0.577s-0.090-0.428-0.236-0.577l0 0c-0.149-0.144-0.352-0.233-0.576-0.233-0 0-0.001 0-0.001 0h0z"></path>
						<path fill="#20245b" d="M37.251 18.594c1.346 0.715 2.943 1.135 4.638 1.135 0.001 0 0.002 0 0.002 0h-0c0.090 0.008 0.195 0.012 0.3 0.012 0.642 0 1.245-0.164 1.77-0.453l-0.019 0.010c0.365-0.291 0.596-0.736 0.596-1.234 0-0.043-0.002-0.086-0.005-0.129l0 0.006c0-0.015 0.001-0.033 0.001-0.050 0-0.374-0.111-0.722-0.301-1.014l0.004 0.007c-0.272-0.358-0.606-0.653-0.988-0.874l-0.016-0.008c-0.485-0.291-1.202-0.679-2.196-1.154-1.243-0.588-2.307-1.338-3.221-2.241l0.001 0.001c-0.769-0.802-1.243-1.892-1.243-3.094 0-0.048 0.001-0.095 0.002-0.143l-0 0.007c-0.003-0.055-0.004-0.12-0.004-0.185 0-0.955 0.315-1.836 0.846-2.546l-0.008 0.011c0.571-0.712 1.335-1.248 2.212-1.533l0.033-0.009c0.919-0.308 1.977-0.486 3.076-0.486 0.045 0 0.091 0 0.136 0.001l-0.007-0c0.035-0 0.076-0.001 0.117-0.001 0.952 0 1.876 0.122 2.756 0.352l-0.076-0.017c0.702 0.159 1.322 0.404 1.89 0.73l-0.033-0.017-0.616 2.909c-0.508-0.323-1.094-0.603-1.712-0.812l-0.058-0.017c-0.62-0.203-1.334-0.321-2.075-0.321-0.034 0-0.068 0-0.102 0.001l0.005-0c-0.078-0.006-0.169-0.010-0.261-0.010-0.666 0-1.288 0.184-1.82 0.503l0.016-0.009c-0.396 0.286-0.651 0.746-0.651 1.266 0 0.024 0.001 0.047 0.002 0.071l-0-0.003c-0 0.013-0.001 0.028-0.001 0.044 0 0.379 0.118 0.73 0.32 1.019l-0.004-0.006c0.259 0.336 0.573 0.616 0.93 0.83l0.015 0.008c0.407 0.267 1.033 0.616 1.867 1.047 1.068 0.539 1.958 1.064 2.812 1.639l-0.092-0.058c0.652 0.445 1.193 1.003 1.606 1.65l0.014 0.023c0.385 0.628 0.613 1.387 0.613 2.2 0 0.043-0.001 0.086-0.002 0.128l0-0.006c0.001 0.040 0.002 0.086 0.002 0.133 0 0.942-0.289 1.817-0.783 2.54l0.010-0.016c-0.542 0.752-1.28 1.331-2.14 1.666l-0.032 0.011c-0.908 0.363-1.961 0.574-3.064 0.574-0.065 0-0.13-0.001-0.195-0.002l0.010 0c-0.784-0.001-1.554-0.063-2.304-0.181l0.084 0.011c-0.772-0.116-1.441-0.263-2.092-0.45l0.114 0.028c-0.46-0.119-0.864-0.285-1.237-0.498l0.025 0.013z"></path>
						<path fill="#20245b" d="M62.017 16.625h-7.981c-0 0.006-0 0.012-0 0.019 0 0.969 0.401 1.843 1.046 2.468l0.001 0.001c0.638 0.514 1.459 0.825 2.352 0.825 0.070 0 0.139-0.002 0.208-0.006l-0.010 0c0.67-0.015 1.313-0.090 1.935-0.22l-0.068 0.012c0.6-0.092 1.139-0.261 1.64-0.5l-0.035 0.015 0.601 2.095c-0.588 0.436-1.28 0.771-2.030 0.961l-0.041 0.009c-0.861 0.249-1.851 0.393-2.874 0.393-0.012 0-0.025-0-0.037-0h0.002c-0.061 0.002-0.132 0.003-0.204 0.003-1.194 0-2.318-0.301-3.3-0.831l0.037 0.018c-0.949-0.537-1.696-1.337-2.154-2.301l-0.013-0.031c-0.465-1.008-0.735-2.187-0.735-3.429 0-0.083 0.001-0.166 0.004-0.248l-0 0.012c-0.001-0.038-0.001-0.084-0.001-0.129 0-1.212 0.285-2.357 0.792-3.372l-0.020 0.044c0.491-1.019 1.246-1.845 2.176-2.41l0.025-0.014c0.959-0.545 2.106-0.865 3.328-0.865 0.064 0 0.128 0.001 0.192 0.003l-0.009-0c0.031-0.001 0.067-0.001 0.104-0.001 0.92 0 1.796 0.187 2.592 0.526l-0.043-0.016c0.798 0.348 1.448 0.914 1.89 1.621l0.010 0.018c0.465 0.791 0.739 1.742 0.739 2.757 0 0.053-0.001 0.107-0.002 0.16l0-0.008c0.003 0.123 0.005 0.269 0.005 0.415 0 0.708-0.044 1.406-0.13 2.091l0.008-0.082zM58.725 14.119c0.004-0.055 0.006-0.119 0.006-0.183 0-0.583-0.184-1.123-0.497-1.566l0.006 0.008c-0.354-0.37-0.851-0.599-1.402-0.599-0.061 0-0.122 0.003-0.181 0.008l0.008-0.001c-0.033-0.002-0.072-0.003-0.11-0.003-0.628 0-1.2 0.242-1.627 0.639l0.001-0.001c-0.487 0.584-0.795 1.331-0.834 2.149l-0 0.008h4.616c0.010-0.107 0.015-0.257 0.015-0.461z"></path>
						<path fill="#20245b" d="M69.115 2.991v19.675h-3.69v-19.675z"></path>
						<path fill="#20245b" d="M72.242 11.729v-2.158l1.833-0.402v-1.241c-0.004-0.073-0.006-0.159-0.006-0.245 0-0.985 0.276-1.904 0.756-2.687l-0.013 0.022c0.453-0.7 1.093-1.245 1.849-1.57l0.027-0.010c0.716-0.307 1.549-0.485 2.424-0.485v0c0.501 0.002 0.992 0.035 1.474 0.098l-0.058-0.006c0.366 0.037 0.695 0.097 1.016 0.18l-0.046-0.010v2.604c-0.475-0.173-1.023-0.273-1.595-0.273-0.089 0-0.177 0.002-0.265 0.007l0.012-0.001c-0.022-0.001-0.049-0.002-0.075-0.002-0.519 0-0.985 0.227-1.305 0.587l-0.002 0.002c-0.325 0.421-0.52 0.955-0.52 1.535 0 0.062 0.002 0.123 0.007 0.184l-0-0.008v1.285h3.151v2.56h-3.151v10.972h-3.695v-10.938z"></path>
						<path fill="#20245b" d="M83.345 4.078c0.224-0.338 0.519-0.611 0.865-0.803l0.013-0.006c0.327-0.189 0.719-0.301 1.138-0.301 0.002 0 0.005 0 0.007 0h-0c0.031-0.002 0.068-0.003 0.105-0.003 0.559 0 1.067 0.215 1.448 0.566l-0.001-0.001c0.36 0.352 0.583 0.843 0.583 1.386 0 0.024-0 0.048-0.001 0.072l0-0.003c0 0.001 0 0.002 0 0.003 0 0.41-0.121 0.791-0.33 1.11l0.005-0.008c-0.221 0.34-0.515 0.615-0.86 0.808l-0.012 0.006c-0.332 0.189-0.729 0.301-1.152 0.301-0.001 0-0.002 0-0.002 0h0c-0.011 0-0.023 0-0.036 0-0.384 0-0.746-0.097-1.062-0.268l0.012 0.006c-0.63-0.329-1.052-0.978-1.052-1.725 0-0.012 0-0.025 0-0.037l-0 0.002c0-0.001 0-0.001 0-0.002 0-0.411 0.125-0.794 0.339-1.111l-0.004 0.007zM87.156 9.168v13.498h-3.695v-13.498z"></path>
						<path fill="#20245b" d="M40.655 26.007c0.178 0.027 0.329 0.059 0.476 0.099l-0.030-0.007 0.209 0.044-0.131 0.795-0.15-0.078c-0.101-0.055-0.218-0.097-0.342-0.12l-0.008-0.001c-0.138-0.031-0.297-0.049-0.461-0.049-0.009 0-0.017 0-0.026 0h0.001c-0.004-0-0.009-0-0.014-0-0.229 0-0.444 0.063-0.627 0.173l0.006-0.003c-0.192 0.117-0.344 0.282-0.443 0.478l-0.003 0.007c-0.108 0.21-0.171 0.458-0.171 0.72 0 0.020 0 0.039 0.001 0.058l-0-0.003c-0 0.010-0 0.021-0 0.032 0 0.271 0.059 0.529 0.165 0.76l-0.005-0.011c0.1 0.222 0.254 0.405 0.447 0.535l0.004 0.003c0.191 0.119 0.422 0.189 0.67 0.189 0.012 0 0.023-0 0.034-0l-0.002 0c0.003 0 0.007 0 0.011 0 0.133 0 0.263-0.014 0.389-0.041l-0.012 0.002c0.143-0.029 0.266-0.066 0.385-0.112l-0.016 0.006c0.094-0.029 0.176-0.069 0.251-0.119l-0.004 0.002v0.693l-0.315 0.116c-0.089 0.028-0.202 0.055-0.317 0.075l-0.017 0.003c-0.132 0.021-0.285 0.034-0.441 0.034h-0c-0.039 0.002-0.084 0.004-0.13 0.004-0.369 0-0.718-0.085-1.028-0.238l0.014 0.006c-0.321-0.176-0.577-0.434-0.747-0.747l-0.005-0.010c-0.171-0.336-0.271-0.732-0.271-1.151s0.1-0.816 0.278-1.166l-0.007 0.015c0.179-0.318 0.439-0.573 0.751-0.742l0.010-0.005c0.301-0.159 0.659-0.252 1.038-0.252 0.014 0 0.027 0 0.041 0l-0.002-0c0.071-0.005 0.153-0.007 0.236-0.007 0.108 0 0.215 0.004 0.32 0.013l-0.014-0.001z"></path>
						<path fill="#20245b" d="M52.446 26.919c0.166-0.304 0.411-0.546 0.708-0.703l0.009-0.005c0.295-0.147 0.643-0.233 1.011-0.233 0.016 0 0.032 0 0.048 0l-0.002-0c0.015-0 0.033-0.001 0.050-0.001 0.362 0 0.701 0.099 0.991 0.272l-0.009-0.005c0.293 0.18 0.524 0.438 0.665 0.746l0.004 0.011c0.14 0.329 0.221 0.711 0.221 1.113 0 0.448-0.101 0.872-0.282 1.251l0.008-0.018c-0.164 0.306-0.41 0.551-0.708 0.708l-0.009 0.004c-0.299 0.15-0.651 0.238-1.024 0.238-0.015 0-0.030-0-0.045-0l0.002 0c-0.012 0-0.026 0-0.040 0-0.368 0-0.711-0.105-1.001-0.286l0.008 0.005c-0.294-0.187-0.524-0.451-0.665-0.765l-0.004-0.011c-0.136-0.304-0.215-0.659-0.215-1.032 0-0.031 0.001-0.062 0.002-0.093l-0 0.004c-0-0.014-0-0.030-0-0.047 0-0.422 0.102-0.819 0.283-1.17l-0.007 0.014zM55.098 27.36c-0.070-0.195-0.187-0.359-0.338-0.483l-0.002-0.001c-0.143-0.118-0.328-0.19-0.529-0.19-0.010 0-0.020 0-0.030 0.001l0.001-0c-0.004-0-0.008-0-0.012-0-0.203 0-0.394 0.058-0.554 0.158l0.004-0.003c-0.176 0.116-0.311 0.282-0.386 0.478l-0.002 0.007c-0.093 0.231-0.147 0.499-0.147 0.779 0 0.026 0 0.052 0.001 0.078l-0-0.004c-0 0.009-0 0.020-0 0.031 0 0.261 0.043 0.512 0.121 0.747l-0.005-0.017c0.071 0.212 0.193 0.39 0.352 0.527l0.002 0.001c0.161 0.123 0.365 0.197 0.587 0.197 0.313 0 0.592-0.149 0.769-0.379l0.002-0.002c0.177-0.287 0.282-0.634 0.282-1.006 0-0.050-0.002-0.1-0.006-0.149l0 0.007c0-0.017 0.001-0.038 0.001-0.059 0-0.256-0.041-0.503-0.117-0.734l0.005 0.017z"></path>
						<path fill="#20245b" d="M70.817 30.303h-0.999c-0.021-0.028-0.041-0.060-0.057-0.094l-0.001-0.003c0-0.034-0.039-0.078-0.063-0.131l-0.039-0.082c-0.030-0.058-0.059-0.129-0.084-0.202l-0.003-0.012-0.107-0.301c-0.058-0.179-0.112-0.325-0.155-0.431-0.041-0.105-0.096-0.195-0.166-0.272l0.001 0.001c-0.058-0.068-0.142-0.111-0.237-0.111h-0.567v1.639h-0.907v-4.325h1.566c0.026-0.001 0.056-0.002 0.086-0.002 0.384 0 0.743 0.109 1.047 0.298l-0.008-0.005c0.265 0.208 0.434 0.528 0.434 0.888 0 0.029-0.001 0.057-0.003 0.085l0-0.004c0.001 0.023 0.002 0.049 0.002 0.076 0 0.266-0.069 0.515-0.19 0.732l0.004-0.008c-0.125 0.198-0.308 0.348-0.526 0.429l-0.007 0.002c0.106 0.093 0.193 0.204 0.259 0.328l0.003 0.006c0.078 0.141 0.165 0.33 0.267 0.567 0.098 0.244 0.191 0.44 0.293 0.631l-0.017-0.034zM68.335 28.019c0.092 0 0.291 0 0.591 0 0.019 0.002 0.042 0.003 0.064 0.003 0.198 0 0.377-0.083 0.503-0.216l0-0c0.112-0.136 0.18-0.312 0.18-0.504 0-0.012-0-0.024-0.001-0.036l0 0.002c0-0.006 0-0.012 0-0.019 0-0.11-0.021-0.215-0.060-0.311l0.002 0.006c-0.051-0.102-0.134-0.181-0.235-0.227l-0.003-0.001c-0.128-0.056-0.277-0.088-0.433-0.088-0.018 0-0.036 0-0.054 0.001l0.003-0c-0.016-0-0.035-0.001-0.054-0.001-0.177 0-0.349 0.021-0.513 0.062l0.015-0.003z"></path>
						<path fill="#20245b" d="M82.25 25.978h1.275c0.037-0.003 0.079-0.004 0.122-0.004 0.399 0 0.767 0.135 1.060 0.361l-0.004-0.003c0.252 0.255 0.408 0.605 0.408 0.992 0 0.043-0.002 0.086-0.006 0.128l0-0.005c0 0.013 0.001 0.027 0.001 0.042 0 0.29-0.074 0.563-0.204 0.801l0.004-0.009c-0.139 0.231-0.339 0.413-0.579 0.525l-0.008 0.003c-0.26 0.114-0.562 0.18-0.881 0.18-0.023 0-0.046-0-0.068-0.001l0.003 0h-0.485v1.314h-0.902v-4.325zM83.971 28.111c0.15-0.149 0.243-0.356 0.243-0.584 0-0.032-0.002-0.063-0.005-0.094l0 0.004c0.002-0.019 0.002-0.040 0.002-0.062 0-0.214-0.086-0.408-0.226-0.549l0 0c-0.154-0.134-0.357-0.215-0.579-0.215-0.021 0-0.042 0.001-0.063 0.002l0.003-0h-0.272c-0 0-0 0-0 0-0.070 0-0.137 0.016-0.196 0.045l0.003-0.001v1.629c0.072 0.005 0.157 0.007 0.242 0.007s0.17-0.003 0.254-0.008l-0.012 0.001c0.021 0.002 0.046 0.003 0.070 0.003 0.2 0 0.386-0.063 0.539-0.169l-0.003 0.002z"></path>
						<path fill="#b3b3b3" opacity="0.29" d="M16.141 5.891c-4.992 0.005-9.143 3.602-10.008 8.345l-0.009 0.062c0 2.104 0.868 3.845 2.225 4.116l0.111-0.053c0.091 0.037 0.196 0.058 0.307 0.058 0.030 0 0.060-0.002 0.090-0.005l-0.004 0c1.278-0.006 2.395-0.692 3.007-1.715l0.009-0.016c0.43-0.767 0.879-1.703 1.274-2.668l0.064-0.178c0.692-1.6 1.496-2.978 2.439-4.245l-0.039 0.055c1.537-1.939 3.505-2.545 5.852-2.245-1.511-0.949-3.347-1.513-5.315-1.513-0.001 0-0.002 0-0.004 0h0z"></path>
						<path fill="#b3b3b3" opacity="0.29" d="M15.859 26.109c4.992-0.005 9.143-3.602 10.008-8.345l0.009-0.062c0-2.104-0.868-3.845-2.225-4.116l-0.111 0.053c-0.091-0.037-0.196-0.058-0.307-0.058-0.030 0-0.060 0.002-0.090 0.005l0.004-0c-1.278 0.006-2.395 0.692-3.007 1.715l-0.009 0.016c-0.43 0.767-0.879 1.703-1.274 2.668l-0.064 0.178c-0.692 1.6-1.496 2.978-2.439 4.245l0.039-0.055c-1.537 1.939-3.505 2.545-5.852 2.245 1.511 0.949 3.347 1.513 5.315 1.513 0.001 0 0.002 0 0.004 0h-0z"></path>
						<path fill="#20245b" d="M16.781 20.364c-0.97-0.485-1.896-0.97-2.846-1.396-1.601-0.717-2.977-1.527-4.251-2.468l0.061 0.043c-1.939-1.537-2.545-3.515-2.245-5.867-0.977 1.532-1.557 3.4-1.557 5.404 0 5.050 3.686 9.238 8.515 10.021l0.058 0.008c2.051-0.029 3.675-0.897 3.942-2.221v-0.131c0.035-0.096 0.055-0.207 0.055-0.323 0-0.023-0.001-0.045-0.002-0.068l0 0.003c-0.006-1.275-0.693-2.389-1.715-2.997l-0.016-0.009z"></path>
						<path fill="#20245b" d="M15.219 11.636c0.97 0.485 1.896 0.97 2.846 1.396 1.601 0.717 2.977 1.527 4.251 2.468l-0.061-0.043c1.939 1.537 2.545 3.515 2.245 5.867 0.977-1.532 1.557-3.4 1.557-5.404 0-5.050-3.686-9.238-8.515-10.021l-0.058-0.008c-2.051 0.029-3.675 0.897-3.942 2.221v0.131c-0.035 0.096-0.056 0.207-0.056 0.323 0 0.023 0.001 0.045 0.002 0.068l-0-0.003c0.006 1.275 0.693 2.389 1.715 2.997l0.016 0.009z"></path>
					</symbol>
					<symbol id="icon-location" viewBox="0 0 32 32">
						<path d="M11.163 11.554c0-2.655 2.166-4.807 4.837-4.807s4.837 2.152 4.837 4.807-2.166 4.806-4.837 4.806-4.837-2.152-4.837-4.806zM7.777 12.154c0 2.011 2.454 6.25 2.454 6.25l5.769 9.614 5.438-9.613c0 0 2.785-4.27 2.785-6.25 0-4.513-3.682-8.171-8.223-8.171s-8.223 3.657-8.223 8.17z"></path>
					</symbol>
				</svg>
			</div>
		</>
	)
}

export default IndexPage