import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

// const mapStyles = {
//   width: '100%',
//   height: '100%'
// }

export class MapContainer extends Component {
    render() {
        return (
            <Map google = {this.props.google}
                zoom = {15}
                style = {this.props.mapSize}
                styles = {this.props.mapStyles}
                initialCenter = {{
                        lat: 1.296934,
                        lng: 103.786833
                }}
            >
                <Marker 
                    title = {'the Hive Lavender, Level 6, Vanguard Building, 1 Kallang Junction, Singapore 339263'}
                    name = {'Mercurics'}
                    position = {{
                        lat: 1.3127683509455952,
                        lng: 103.86444329761501
                    }} 
                />
            </Map>

        )
    }
}

MapContainer.defaultProps = {
    mapStyles: [

        {
            elementType: 'labels.text.fill',
            stylers: [{
                color: '#d12c91'
            }]
        },
        {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
                    "hue": "#dc3965"
                },

                {
                    "lightness": 20
                },
                {
                    "gamma": 1
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
                    "hue": "#0099ff"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [

                {
                    "lightness": 70
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [{
                    "visibility": "on"
                },
                {
                    "color": "#dc3965"
                },
                {
                    "weight": "0.5"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }]
        },

        {
            "featureType": "transit.station.bus",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
                    "hue": "#dc3965"
                },
                {
                    "saturation": 8.400000000000006
                },
                {
                    "lightness": 36.400000000000006
                },
                {
                    "gamma": 1
                }
            ]
        }
    ]
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAY3fkrv-LfC3LnBpXJfM2d3xgIe7V5yaI'
})(MapContainer)